<template>
  <div>
    <div class="home">
      <LogoHeader />
      <router-view />
      <Footer :white-footer="true" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LogoHeader from "@/components/organisms/LogoHeader.vue";
import Footer from "@/components/organisms/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "OnBoarding",
  components: {
    LogoHeader,
    Footer,
  },
});
</script>

<style lang="scss" scoped>
.home {
  font-weight: 400;
  // letter-spacing: 0.15em;
  line-height: 2em;
  height: calc(100vh);
  overflow: auto;
  background: linear-gradient(-15deg, $placeholder1 50%, $secondary3 50%);
  @include mq(m) {
    min-height: calc(100vh - 215px);
    height: auto;
  }
}
</style>
